import { v4 as uuidv4 } from 'uuid';

var currentUser;

function geocodeAddress(row, callback) {
  var geocoder = new window.google.maps.Geocoder();  
  let locData = {};
  geocoder.geocode({'address': row['Full Address']}, function(results, status) {
    if (status === 'OK') {
      locData = {
          'Location': results[0].geometry.location,
          'Latitude': results[0].geometry.location.lat(),
          'Longitude': results[0].geometry.location.lng(),
          'PlaceID': results[0].place_id, 
          'Formatted Address': results[0].formatted_address
      };
      updateCell(row['Sheet'], row['Latitude Range'], results[0].geometry.location.lat(), null, ()=> console.log(row['Formatted Address'], 'lat not saved'));
      updateCell(row['Sheet'], row['Longitude Range'], results[0].geometry.location.lng(), null, ()=> console.log(row['Formatted Address'], 'lng not saved'));
      callback(locData);
    } else { 
      callback(status);
    }
  });
}

function updateCell(sheet, range, value, successCallback, errorCallback) {
  var request = window.gapi.client.sheets.spreadsheets.values.update({
    spreadsheetId: '1uIg3k5NpNqhZsU5cr5drl5sZ4pdYIAcX1gyqm4skAYo',
    range: "'"+sheet+"'!" + range,
    valueInputOption: 'RAW',
    values: [ [value] ]
  });
  request.then(successCallback, errorCallback);
}

export function getSheetData(params, callback){
  const sheetInfo = [{
    'title': "'Closed Restaurants for Lease'", 
    'latCol': 'R',
    'longCol': 'S',
    'color': 'red'
  },{
    'title': "'New Construction Restaurant Locations'",
    'latCol': 'S',
    'longCol': 'T',
    'color': 'green'
  },{
    'title': "'Business Sales'",
    'latCol': 'S',
    'longCol': 'T',
    'color': 'blue'
  },{
    'title': "'Restaurants w/ Real Estate for Sale'",
    'latCol': 'P',
    'longCol': 'Q',
    'color': 'yellow'
  }]
  var request = window.gapi.client.sheets.spreadsheets.values.batchGet({
    'spreadsheetId': '1uIg3k5NpNqhZsU5cr5drl5sZ4pdYIAcX1gyqm4skAYo',
    'ranges': [...sheetInfo.map(x => x.title)]
  })
  request.then(response => {
    var data = response.result;
    if (data) {
      var newData = [];
      var geocodedData = [];
      data.valueRanges.forEach(valueRange => {
        var sheet = valueRange.range.split("'");
        var headers = valueRange.values[0];
        var rows = valueRange.values.slice(1);
        for (var i=0; i<rows.length; i++) {
          var rowObject = {};
          var rowNum = i+2;
          for (var j=0; j<rows[i].length; j++) {
            rowObject[headers[j]] = rows[i][j];
          }
          let address = rowObject['Address'] + ', ' + rowObject['City'] + ' ' + rowObject['Zip'];
          let tab = sheet[1];
          rowObject['UUID'] = uuidv4();
          rowObject['Full Address'] = address;
          rowObject['Sheet'] = tab;
          rowObject['Latitude Range'] = sheetInfo.filter(x => x['title'] === valueRange.range.split('!')[0])[0].latCol + rowNum;
          rowObject['Longitude Range'] = sheetInfo.filter(x => x['title'] === valueRange.range.split('!')[0])[0].longCol + rowNum;
          rowObject['Color'] = sheetInfo.filter(x => x['title'] === valueRange.range.split('!')[0])[0].color;
          // let test = newData.filter(x => address === x['Full Address']);
          // if (test.length < 1 && rowObject['Size']) {
            newData.push(rowObject);
          // }
        }
      });
      newData.forEach(row => {
      if (!row['Latitude']){
        geocodeAddress(row, (locData) => {
          if (locData['Location']) {
            row['Location'] = locData['Location'];
          } 
        });
      }
      
      geocodedData.push(row);
      });
      if(geocodedData){
        callback({data: geocodedData}); 
      }
    }
  }).catch(err => {
    console.log(err); 
    callback({data: null});
  });
}

export function appendValues(sheetId, sheetArray, sheetUrl){
    console.log(sheetArray)
    window.gapi.client.sheets.spreadsheets.values.append({
        spreadsheetId: sheetId,
        range: 'Sheet1',
        valueInputOption: 'USER_ENTERED',
        resource: { values: sheetArray } 
    }).then(response => {
        if(response.result.error){
            console.log(response.result.error);
        } else {
          var requests = [
              {
                "repeatCell": {
                  "range": {
                    "sheetId": 0,
                    "startRowIndex": 0,
                    "endRowIndex": 1
                  },
                  "cell": {
                    "userEnteredFormat": {
                      "horizontalAlignment" : "CENTER",
                      "textFormat": {
                        "fontSize": 12,
                        "bold": true
                      }
                    }
                  },
                  "fields": "userEnteredFormat(backgroundColor,textFormat,horizontalAlignment)"
                }
              },
              {
                "updateSheetProperties": {
                  "properties": {
                    "sheetId": 0,
                    "gridProperties": {
                      "frozenRowCount": 1
                    }
                  },
                  "fields": "gridProperties.frozenRowCount"
                }
              },
              {
                "updateBorders": {
                  "range": {
                    "sheetId": 0,
                    "startRowIndex": 1
                  },
                  "innerHorizontal": {
                    "style": "SOLID"
                  },
                  "innerVertical": {
                    "style": "SOLID"
                  }
                }
              }
            ]
          
          var batchUpdateRequest = {requests: requests}
          window.gapi.client.sheets.spreadsheets.batchUpdate({
            spreadsheetId: sheetId,
            resource: batchUpdateRequest
          }).then(response => {
            if(response.result.error){
              console.log(response.result.error);
            } else {
              window.open(sheetUrl, '_blank');
            }
          })
        }
    }).catch(error => {console.log(error)});
}

export function makeApiCall(params, callback) {
    var request;
    if (params && params.sheetArray) {
    var spreadsheetBody = {
       properties: { title: 'My Starred Properties'}
    };
    request = window.gapi.client.sheets.spreadsheets.create({}, spreadsheetBody);
    request.then(function(response) {
      appendValues(response.result.spreadsheetId, params.sheetArray, response.result.spreadsheetUrl);
    }, function(reason) {
      console.error('error: ' + reason.result.error.message);
    });
  } else if (params && params.checkAccess){
    getSheetData(params, callback);
  }
}

export function initClient(params, callback) {

    var API_KEY = 'AIzaSyAnkkxzGceDhuEiJEsgMjVAaPeU5sxr-xk'; 
    var CLIENT_ID = '834525972755-klotobadbtd1e1jis6nj3qg63tm42jos.apps.googleusercontent.com';

    window.gapi.client.init({
      'apiKey': API_KEY,
      'clientId': CLIENT_ID,
      'discoveryDocs': ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
      'scope': 'https://www.googleapis.com/auth/spreadsheets'
    }).then(() => {
      if(params && params.pageLoad){
        window.gapi.auth2.getAuthInstance().then(auth => {
            currentUser = auth.currentUser.get();
            if(callback){
              callback(auth.isSignedIn.get());
            } else {
              resetUser(currentUser);
            }
        });
      }
      else {
        window.gapi.auth2.getAuthInstance().isSignedIn.listen(updateSignInStatus);
        // window.gapi.auth2.getAuthInstance().currentUser.listen(resetUser);
        updateSignInStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get(), params, callback);
      }
    }).catch( error => {
        console.log(error);
    });
  }

export function handleClientLoad(params, callback) {
    window.gapi.load('client:auth2', () => {
        setTimeout(initClient(params, callback), 2000);
    });
  }

export function updateSignInStatus(isSignedIn, params, callback) {
    if (isSignedIn) {
        if(params && (params.sheetArray || params.checkAccess)){
            makeApiCall(params, callback);
        } else if(params && params.pageLoad){
            makeApiCall({checkAccess: true}, callback);
        }
    } else {
      initClient({pageLoad:true}, null);
    }
  }

export function handleAuthClick(event, callback) {
    window.gapi.auth2.getAuthInstance().signIn().then( () => callback(window.gapi.auth2.getAuthInstance().isSignedIn.get()));
  }

export function handleSignoutClick(event, callback) {
    window.gapi.auth2.getAuthInstance().signOut().then( () => callback(window.gapi.auth2.getAuthInstance().isSignedIn.get()));
  }

export function resetUser(newUser){
  if(newUser && newUser.getAuthResponse().currentUser){
    var user = newUser.getAuthResponse().currentUser.get();
    if (user !== currentUser){
        setTimeout(window.location.reload(true), 5000);
    }
  } else {
    setTimeout(window.location.reload(true), 5000);
  }
}

export default handleClientLoad;