import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Login from "./components/login.component";
import Map from "./components/Map";
import { handleClientLoad } from "./helpers/sheets";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: false,
      show: false
    };
  }

  setDrawerState = (show) => {
    this.setState({show: show})
  }

  closeDrawerApp = () => {
    this.setState({show: false})
  }

  onLoad = (authState) => {
    console.log('from onload',authState);
    this.setState({auth: authState});
    if(authState){
      handleClientLoad({checkAccess: true}, response => {
        console.log('checkAccess', response);
        if(response.data){
          this.setState({data: response.data});
        } else {
          this.setState({data: null});
        }
      });
    } else {
      handleClientLoad({pageLoad: true}, response => {
        console.log('pageLoad', response);
        if(response){
          this.setState({auth: response, signin: response})
          handleClientLoad({checkAccess: true}, response => {
            console.log('checkAccess');
            if(response.data){
              this.setState({data: response.data});
            } else {
              this.setState({data: null});
            }
          })
        }
      })
    }
  }

  render() {
    return (
        <div className="container">
          <nav className="navbar navbar-expand navbar-dark bg-dark">
            <img id="lm-logo" src="logo-reversed.png" alt="location matters logo" />
              <div className="navbar-nav ml-auto">
                <Login onLoad={this.onLoad} auth={this.state.auth} show={this.state.show} setDrawerState={this.setDrawerState} />
              </div>
          </nav>
          {(this.state.signin || this.state.auth) && this.state.data && (
            <Map data={this.state.data} drawerState={this.state.show} closeDrawerApp={this.closeDrawerApp} />
          )}
          <footer className="App-footer">
            <div id="attribution">&copy;2020 MJSRE | Built by <a href="http://justheatherbree.com">justheatherbree</a> | Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
          </footer>
        </div>
    );
  }
}

export default App;