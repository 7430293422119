import React, { Component } from 'react';
import { Drawer } from 'rsuite';
import FilterForm from './FilterForm';
import 'rsuite/dist/styles/rsuite-default.css';

class Filters extends Component{
    constructor(props) {
      super(props);
      this.state = {
        show: this.props.drawerState
      };
    }
    closeDrawerFilter = () => {
      this.props.closeDrawerMap()
    }
    render() {
      return (
        <div id="drawer">
          <Drawer
            show={this.props.drawerState}
            onHide={this.closeDrawerFilter}
          >
            <Drawer.Header>
              <Drawer.Title>Filter Properties</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
                <FilterForm onChange={this.onChange} sqftRange={this.props.sqftRange} sqftEnd={this.props.sqftEnd} selCategories={this.props.selCategories} categories={this.props.categories} selSubmarkets={this.props.selSubmarkets} submarkets={this.props.submarkets} dateListed={this.props.dateListed} />
            </Drawer.Body>
          </Drawer>
        </div>
      );
    }
    onChange = (type, value, e) => {
        this.props.onChange(type, value, e)
    }
    
  }
  
export default Filters