import React, { Component } from "react";
import { handleAuthClick, handleSignoutClick } from "../helpers/sheets";
import { Button } from 'rsuite';

export default class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      auth: false
    };
    this.inBtn = React.createRef();
    this.outBtn = React.createRef();

  }

  componentDidMount() {
    window.addEventListener('load', this.onLoad);
  }

  authClick = (event) => {
    handleAuthClick(event, response => {
      this.props.onLoad(response);
      this.setState({auth: response});
    });
  }

  signoutClick = (event) => {
    handleSignoutClick(event, response => {
      this.props.onLoad(response);
      this.setState({auth: response});
    });
  }

  toggleDrawer = () => {
    console.log("open filter button clicked, login toggledrawer fired", this.props.show)
    if (this.props.show) {
      this.props.setDrawerState(false)
    } else {
      this.props.setDrawerState(true);
    }
  }

  onLoad = () => {
    this.props.onLoad(this.state.auth);
  }

  render() {
    return (
      <div id='authbuttons'>
        <Button className={ this.props.auth ? "hidden btn" : "btn" } id="authorize_button" ref={this.inBtn} onClick={this.authClick}>Authorize</Button>
        <Button className={ this.props.auth ? "btn" : "hidden btn" } onClick={this.toggleDrawer}>Open Filter</Button>
        <Button className={ this.props.auth ? "btn" : "hidden btn" } id="signout_button" ref={this.outBtn} onClick={this.signoutClick}>Sign Out</Button>
      </div>
    );
  }
}
