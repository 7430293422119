import React, { Component, createRef } from 'react';
import Filters from './Filters';
import List from './List';
import { handleClientLoad } from '../helpers/sheets';
import debounce from 'lodash/debounce';
import SmartQueue from "smart-request-balancer";
import OverlappingMarkerSpiderfier from 'overlapping-marker-spiderfier';

const queue = new SmartQueue({
  rules: {
    placePhotos: {
      rate: 1,
      limit: 1, 
      priority: 1
    }
  },
  default: {                   // Default rules (if provided rule name is not found)
    rate: 1,
    limit: 1
  },
  overall: {                   // Overall queue rates and limits
    rate: 1,
    limit: 1
  },
  retryTime: 300,
});

var initState = {
    sqftEnd: [], // min/max of all source data
    submarketsList: [], // unique list of all possible values
    categoriesList: [], // unique list of all possible values
    markers: [], 
    list: []
};

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sqftRange: [], // current values from form
            submarkets: [], // current selected values
            categories: [], // current selected values
            dateListed: [], // current range from form
            markers: [],
            userLikes: [],
            list: [],
            map: {},
        }
        this.googleMapRef = createRef();
        this.onChangeDebounced = debounce(this.onChangeDebounced, 2000);
        this.infowindow = new window.google.maps.InfoWindow();
    }

    componentDidMount() {
        this.setMinMaxInputRange(this.props.data);
        this.setSubmarkets(this.props.data);
        this.setCategories(this.props.data);
        this.googleMap = this.createGoogleMap();
        this.createMarkers(this.props.data);
        this.createList(this.props.data);
        this.places = new window.google.maps.places.PlacesService(this.googleMap);
    }

    createList(data){
        var allMarkers = this.state.markers;
        if (allMarkers.length > 0){
            var visibleMarkers = [];
            allMarkers.forEach( marker => {
                if(marker.visible === true){
                    visibleMarkers.push(marker);
                }
            })
            visibleMarkers = visibleMarkers.map(x => x.title);
            var visibleList = [];
            data.forEach( location => {
                if (visibleMarkers.includes(location['Full Address'])){
                    visibleList.push(location);
                }
            });
            this.setState({
                list: visibleList
            });
        } else {
            this.setState({
                list: data
            });
        }
        if (initState.list.length > 0 && visibleList){
            if (initState.list.length < visibleList.length) {
                initState.list = visibleList;
            }
        } else {
            initState.list = data;
        }
    }

    setMinMaxInputRange(data){
        var sqftTxt = data.map(x => x['Size'].split('-'));
        var sqft = [];
        sqftTxt.forEach( x => {            
            x.forEach(value => {
                if(value){
                    sqft.push(parseInt(value.replace(/[^0-9]/g, '')));
                }
            });
        });
        this.setState({
            sqftRange: [Math.min(...sqft), Math.max(...sqft)]
        });
        initState.sqftEnd = [Math.min(...sqft), Math.max(...sqft)];
    }

    setSubmarkets(data){
        var submarkets = [];
        data.forEach(x => submarkets.push(x['Submarket']));
        let uniqueSet = [...new Set(submarkets)];
        let sortedList = uniqueSet.sort((a, b) => {
            if (a < b) return -1;
            else if (a > b) return 1;
            return 0;
        })
        var submarketsList = [];
        sortedList.forEach(value => {
            submarketsList.push({value:value, label:value})
        })
        // this.setState({ submarkets: sortedList });
        initState.submarketsList = submarketsList;
    }

    setCategories(data){
        var categories = [];
        data.forEach(x => categories.push(x['Sheet']));
        let uniqueSet = [...new Set(categories)];
        let sortedList = uniqueSet.sort((a,b) => {
            if (a < b) return -1;
            else if (a > b) return 1;
            return 0;
        })
        var categoriesList = [];
        sortedList.forEach(value => {
            categoriesList.push({value:value, label:value})
        })
        // this.setState({ categories: sortedList })
        initState.categoriesList = categoriesList;
    }

    createGoogleMap = () => {
        var googleMap = new window.google.maps.Map(this.googleMapRef.current, {
            zoom: 8,
            center: {
                lat: 32.9557,
                lng: -117.1611,
            },
        });
        googleMap.addListener('bounds_changed', () => this.zoomChanged())
        this.setState({
            map: googleMap
        });
        var legendInfo = {}
        var colors = [...new Set(this.props.data.map(x => x['Color']))]
        colors.forEach(c => {
            var sheet = this.props.data.filter(x => x['Color']===c)
            legendInfo[c] = {name: sheet[0]['Sheet'], 
                icon: `https://maps.google.com/mapfiles/ms/icons/${c}-dot.png`}
        })
        var legend = document.getElementById('legend');
        for (var key in legendInfo) {
            var type = legendInfo[key];
            var name = type.name;
            var icon = type.icon;
            var div = document.createElement('div');
            div.innerHTML = `<img src="${icon}"> <span>${name}</span>`
            legend.appendChild(div);
        }
        this.displayLegend(legend);
        return googleMap;
    }

    displayLegend = (legend) => {
        setTimeout(() => {
            this.googleMap.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(legend);
            document.getElementById('legend').style.display = 'block'
        }, 7000)
    }

    createMarkers(mapData) {
        var omsOptions = {keepSpiderfied:true, ignoreMapClick:true, nearbyDistance:10}
        var oms = new OverlappingMarkerSpiderfier(this.googleMap, omsOptions);
        var bounds = new window.google.maps.LatLngBounds();
        const markers = [];
        mapData.forEach(location => {
            if (location['Latitude']) {
                var marker = new window.google.maps.Marker({
                    position: { lat:parseFloat(location['Latitude']), lng:parseFloat(location['Longitude']) },
                    title: location['Formatted Address'] ? location['Formatted Address'] : location['Full Address'],
                    uuid: location['UUID'],
                    visible: true,
                    map: this.googleMap,
                    icon: {
                        url: `https://maps.google.com/mapfiles/ms/icons/${location['Color']}-dot.png`
                    }
                });
                markers.push(marker);
                oms.addMarker(marker);
                bounds.extend(marker.position);
            }
        });
        oms.addListener('click', (marker, event) => {
            this.infoCallback(marker)
        })
        oms.addListener('spiderfy', (all) => {
            var spiderBounds = new window.google.maps.LatLngBounds();
            all.forEach(leg => spiderBounds.extend(leg.position))
            // this.googleMap.fitBounds(spiderBounds)
        })
        // this.googleMap.fitBounds(bounds);
        // this.googleMap.panToBounds(bounds);
        this.setState({ markers: markers });
        if (initState.markers.length > 0){
            if (initState.markers.length < markers.length) {
                initState.markers = markers;
            }
        } else {
            initState.markers = markers;
        }
    }

    infoCallback = (marker) => {
        var listAll = document.querySelectorAll("div#list-scroll ul li");
        [...listAll].forEach(item => {
            if (item.classList){
                item.classList.remove("bold")
            }
        })        
        var location = this.state.list.filter(x => x['UUID'] === marker.uuid)
        location = location[0]
        var content = `<div id=content>`;
        var request = {
            query: location['Full Address'],
            fields: ['photos'],
        };
    
        queue.request((retry) => this.places.findPlaceFromQuery(request, (results, status) => {
            let response = {results: results, status: status};  
            if (response.status === 'OK' || response.status === "ZERO_RESULTS") {
                if(response.results && response.results[0].photos){
                    content += `<img id="infoImg" referrerPolicy="no-referrer" src=${response.results[0].photos[0].getUrl({maxWidth: 300, maxHeight: 300})} />`
                } else {
                    content += `<img id="infoImg" src="https://maps.googleapis.com/maps/api/streetview?size=600x600&location=${encodeURIComponent(location['Full Address'])}&key=AIzaSyAnkkxzGceDhuEiJEsgMjVAaPeU5sxr-xk" />`
                }
                content += `<div id="listingInfo">
                    <h4>${location['Full Address']}</h4>
                    <div><p><span class="label">Submarket:</span> 
                    <span class="text">${location['Submarket']}</span></p>
                    <p><span class="label">Unit/Space:</span> 
                    <span class="text">${typeof location['Unit/Space'] !== "undefined" ? location['Unit/Space'] : "unknown"}</span></p>
                    <p><span class="label">Size:</span> 
                    <span class="text">${typeof location['Size'] !== "undefined" ? location['Size'] : "unknown"}</span></p>
                    <p><span class="label">Asking Rent:</span> 
                    <span class="text">${typeof location['Asking Rent'] !== "undefined" ? location['Asking Rent'] : "unknown"}</span></p>`;
                if (location['Sheet']==="Restaurants w/ Real Estate for Sale" || location['Sheet']==="Business Sales") {
                        content += `<p><span class="label">Price:</span> <span class="text">${location['Price']}</span></p>`;
                    }
                content += `<p><span class="label">Broker:</span> <span class="text"><a href="mailto:${location['Email']}">${location['Broker']}</a>, ${location['Broker Firm']}</span></p>
                    <p><span class="label">Date Listed:</span> <span class="text">${location['Date Listed']}</span></p>`
                if (location['NNNs']) {
                    content += `<p><span class="label">NNN:</span> <span class="text">${location['NNNs']}</span></p>`
                }
                content += '</div>'
                if (location['Notes']) {
                    content += `<p>${location['Notes']}</p>`
                }
                if (location['Other']) {
                    content += `<p>${location['Other']}</p>`
                }
                content += `<a href="${location['Brochure']}" target="_blank"><button class="btn rs-btn-default">View Brochure</button></a>
                    </div></div>`;
                this.infowindow.setContent(content);
                this.infowindow.open(this.googleMap, marker);
                var listItem = document.querySelector('[data-uuid="'+location["UUID"]+'"]')
                listItem.classList.add("bold");
                listItem.scrollIntoView();
            } else {
                console.log(response);
                return retry();
            }        
        }), 'default', 'placePhotos');
    }

    setMapOnAll(map) {
        const markers = this.state.markers;
        for (var i = 0; i < markers.length; i++) {
            if (map === null){
                markers[i].setMap(null);
            } else {
                markers[i].setMap(this.googleMap);
            }
        }
    }

    clearMarkers() {
        this.setMapOnAll(null);
    }

    deleteMarkers() {
        this.clearMarkers();
        this.setState({ markers: [] });
    }

    handleFormChange = (type, value, event) => {
        var sqftRange, submarkets, categories, dateListed
        if (type === "clearForm") {
            sqftRange = initState.sqftEnd
            submarkets = []
            categories = []
            dateListed = []
        } else if (type === "submitForm") {
            sqftRange = value.formValue.sqftRange
            submarkets = value.formValue.selSubmarkets
            categories = value.formValue.selCategories
            dateListed = value.formValue.dateListed
        }
        this.setState({sqftRange:sqftRange, submarkets:submarkets, categories:categories, dateListed:dateListed})
        this.onChangeDebounced(sqftRange, submarkets, categories,dateListed);
    }

    onChangeDebounced = (sqftRange, submarkets, categories, dateListed) => {
        let filteredData = this.props.data
        if (categories.length > 0){
            filteredData = filteredData.filter(x => categories.includes(x['Sheet']));
        }
        if (submarkets.length > 0){
            filteredData = filteredData.filter(x => submarkets.includes(x['Submarket']));
        }
        if (dateListed.length > 0) {
            filteredData = filteredData.filter(x => {
                var xDate = new Date(x["Date Listed"])
                if (xDate >= dateListed[0] && xDate <= dateListed[1]){
                    return true
                } else {
                    return false
                }
            })
        }
        filteredData = filteredData.filter(x => {
            var sqft = x['Size'].split('-');
            if (!sqft[0]){ 
                return true; 
            }
            // new math: if sqft range, if first number greater than or equal to form min 
            // and less than or equal to form max or second number less than or equal to form max and 
            // greater than or equal to form min include in data
            if (sqft.length > 1){
                var first = parseInt(sqft[0].replace(/[^0-9]/g, ''));
                var second = parseInt(sqft[1].replace(/[^0-9]/g, ''))
                if (((first >= sqftRange[0]) && (first <= sqftRange[1])) || ((second <= sqftRange[1]) && (second >= sqftRange[0]))) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if ((parseInt(sqft[0].replace(/[^0-9]/g, '')) >= sqftRange[0]) && (parseInt(sqft[0].replace(/[^0-9]/g, '')) <= sqftRange[1])) {
                    return true;
                } else {
                    return false;
                }
            }
        });
        var formErrorBox = document.getElementById("formErrorMsg");
        if (filteredData.length > 0){
            formErrorBox.innerHTML="";
            this.deleteMarkers();
            this.createMarkers(filteredData);
            this.createList(filteredData);
            this.closeDrawerMap()
        } else {
            formErrorBox.innerHTML = "<p class='alert'>No properties match. Widen your search criteria.</p>";
        }
    };

    zoomChanged = () => {
        var currentMarkers = this.state.markers;
        if (currentMarkers.length < 1){
            currentMarkers = initState.markers;
        }
        currentMarkers.forEach( marker => {
            if (this.googleMap.getBounds().contains(marker.getPosition())){
                marker.setVisible(true);
            } else {
                marker.setVisible(false);
            }
        });    
        var currentList = this.state.list;
        if (currentList.length >= currentMarkers.length){
            this.createList(currentList);
        } else {
            this.createList(initState.list);
        }
    }

    render() {
        return (
        <div id="authorized">
            <Filters onChange={this.handleFormChange} sqftRange={this.state.sqftRange} sqftEnd={initState.sqftEnd} selCategories={this.state.categories} categories={initState.categoriesList} selSubmarkets={this.state.submarkets} submarkets={initState.submarketsList} dateListed={this.state.dateListed} drawerState={this.props.drawerState} closeDrawerMap={this.closeDrawerMap}/>
            <div id="columns">
                <div id="list-group">
                    <List data={this.state.list} compileSheetRows={this.compileSheetRows} keepStarredMarkers={this.keepStarredMarkers} places={this.places} queue={queue} />
                </div>
                <div id="map-group">
                    <div
                        id="google-map"
                        ref={this.googleMapRef}
                    />
                    <div id="legend"></div>
                </div>
            </div>
        </div>
        )
    }

    closeDrawerMap = () => {
        this.props.closeDrawerApp()
    }

    keepStarredMarkers = (addresses) => {
        var currentMarkers = this.state.markers;
        var currentUUIDs = currentMarkers.map(x => x.uuid);
        var startingLength = currentUUIDs.length;
        addresses.forEach( address => {
            if (!currentUUIDs.includes(address.uuid)){
                // recreate all markers
                currentUUIDs.push(address)
            } else {
                // set this marker status to visible if not
                var marker = currentMarkers.filter(x => x.uuid === address.uuid);
                if(!marker[0].visible){
                    marker[0].setVisible(true);
                }                                              
            }
        });
        if (currentUUIDs.length > startingLength){
            var filteredData = initState.list.filter(x => currentUUIDs.includes(x['UUID']))
            this.createMarkers(filteredData);
        }
    }

    compileSheetRows = (addresses) => {
        var data = [];
        addresses.forEach(a => {
            var match = initState.list.filter(x => x['UUID'] === a.uuid);
            var row = match.map(r => {
                var brochureLink = ""
                if(r['Brochure'].length > 8 && r['Brochure'].startsWith('http')) {
                    brochureLink = `=HYPERLINK("${r['Brochure']}","Brochure Link")`
                }
                return {'Address': r["Address"], 
                        'City': r['City'],
                        'Zip': r['Zip'],
                        'Submarket': r['Submarket'],
                        'Unit/Space': r['Unit/Space'],
                        'Size': r['Size'],
                        'Patio Size': r['Patio Size'],
                        'Asking Rent': r['Asking Rent'],
                        'NNNs': r['NNNs'],
                        'Key Money': r['Key Money'],
                        'Notes': r['Notes'],
                        'Brochure': brochureLink,
                        'Broker': r['Broker'],
                        'Email': r['Email'],
                        'Broker Firm': r['Broker Firm'],
                        'Date Listed': r['Date Listed']
                    }
            })
            data.push(row[0]);
        });
        console.log(data)
        var sheetArray = [];
        sheetArray.push(Object.keys(data[0]));
        data.forEach(x => sheetArray.push(Object.values(x)));
        this.setState({sheetArray: sheetArray});
        var auth = window.gapi.auth2.getAuthInstance();
        var user = auth.currentUser.get();
        var SCOPE = 'https://www.googleapis.com/auth/spreadsheets';
        var isAuthorized = user.hasGrantedScopes(SCOPE);
        if (isAuthorized){
            handleClientLoad({sheetArray: sheetArray});
        }
    }
}

export default Map;