import React, { Component } from 'react';
import VizSensor from 'react-visibility-sensor';

export default class ListItem extends Component {
    state = {
        viz: false,
        starred: false,
    }

    render() {
        return (
            <VizSensor onChange={(isVisible) => {
                this.setState({viz: isVisible})
            }} >
                <li data-uuid={this.props.x['UUID']} onClick={this.starState} className={this.state.starred ? 'starred' : ''} ><span className="listImg"><img src={`https://maps.googleapis.com/maps/api/streetview?size=100x100&location=${encodeURIComponent(this.props.x['Full Address'])}&key=AIzaSyAnkkxzGceDhuEiJEsgMjVAaPeU5sxr-xk`} alt={this.props.x['Full Address']}/></span><span className="listTxt">{this.props.x['Full Address']}</span></li>
            </VizSensor>    
        )
    }

    // https://maps.googleapis.com/maps/api/streetview?size=600x600&location=358%20E%20H%20St.%2C%20Chula%20Vista%2091910&key=AIzaSyAnkkxzGceDhuEiJEsgMjVAaPeU5sxr-xk

    starState = (e) => {
        var currStarState = this.state.starred;
        this.setState({starred: currStarState ? !currStarState : currStarState})
        this.props.liClick(e, this.props.x);
    }
}