import React, { Component } from 'react';
import ListItem from './ListItem';
import { Button } from 'rsuite';

export default class List extends Component {

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      starred: [], 
      starall: false
    }
  }

  render() {
    var starredItems =[];
    this.state.starred.forEach(star => {
      starredItems.push(
        <li 
          key={star.uuid} 
          data-uuid={star.uuid} 
          onClick={this.liClick} 
          className={[...this.state.starred.filter(a => a.uuid === star.uuid)].length > 0 || this.state.starall ? 'starred' : ''} >
            <span className="listImg">
              <img src={`https://maps.googleapis.com/maps/api/streetview?size=100x100&location=${encodeURIComponent(star.address)}&key=AIzaSyAnkkxzGceDhuEiJEsgMjVAaPeU5sxr-xk`} alt={star.address}/>
            </span>
            <span className="listTxt">{star.address}</span>
        </li>)
    });
    var listItems = this.props.data.filter(x => [...this.state.starred.filter(a => a.uuid === x['UUID'])].length < 1);
    listItems = listItems.map(x => <ListItem x={x} key={x['UUID']} liClick={this.liClick} />);
    return (<div>
      <div id="listForm">
        <span className={
          this.state.starred.length < 1 && !this.state.starall ? "starnone" : "starall"
        } onClick={this.toggleStars} title={
          this.state.starred.length < 1 && !this.state.starall ? "star all" : "star none"
        } id="checkAll"></span>
        <Button onClick={this.saveToSheets}>Save to Sheets</Button>
      </div>
      <div id="list-scroll">
        <ul>
          {starredItems}
          <hr />
          {listItems}
        </ul>
      </div>
      </div>)
    }

  liClick = (e, item) => {
    var starred = this.state.starred;
    var test = starred.filter(x => x.uuid === e.currentTarget.dataset.uuid)
    if (test.length > 0) {
      starred = starred.filter(x => x.uuid !== e.currentTarget.dataset.uuid)
    }
    else {
      starred.push({address: item['Full Address'], uuid: e.currentTarget.dataset.uuid});
    }
    if (starred.length > 0 && this.state.starall){
      this.setState({starall: false})
    } 
    this.setState({
      starred
    })
    this.props.keepStarredMarkers(starred);
  };

  saveToSheets = () => {
    var starred = this.state.starred;
    if(starred.length < 1){
      alert("Click on a property to star it, so you'll have content in your Google Sheet!");
    } else {
      this.props.compileSheetRows(starred, (error) => { alert(`There was an error generating your spreadsheet.<br>${error}`) });
    }
  }

  toggleStars = () => {
    var starall = this.state.starall;
    var starred = this.state.starred;
    if(!starall && starred.length < 1){
      starred = this.props.data.map(x => { return {address: x['Full Address'], uuid: x['UUID']} });
      this.setState({
        starred: starred,
        starall: true
      })    
    } else {
      starred = [];
      this.setState({
        starred: starred,
        starall: false
      })
    }
  }
 
}