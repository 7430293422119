import React, { Component } from 'react';
// import { Multiselect } from 'multiselect-react-dropdown';
import { Form, FormControl, ControlLabel, HelpBlock, Button, CheckPicker, RangeSlider, DateRangePicker } from 'rsuite';

class FilterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formValue: {
                sqftRange:this.props.sqftRange,
                selSubmarkets:this.props.selSubmarkets,
                selCategories:this.props.selCategories,
                dateListed:this.props.dateListed
            }
        }
    }

    render(){
        return (
        <div id="form-group">
            <Form formValue={this.state.formValue} onChange={this.handleChange}>
            <ControlLabel>Square Footage: </ControlLabel>
            <FormControl
                accepter={RangeSlider}
                name="sqftRange"
                label="Sq.Ft."
                min={this.props.sqftEnd[0]}
                max={this.props.sqftEnd[1]} 
            />
            <HelpBlock><span id="beginning">{this.props.sqftEnd[0]} sq.ft.</span><span id="ending">{this.props.sqftEnd[1]} sq.ft.</span></HelpBlock>
            <ControlLabel>Categories: </ControlLabel>
            <FormControl
                name="selCategories"
                accepter={CheckPicker}
                data={this.props.categories}
                block
            />
            <ControlLabel>Submarket: </ControlLabel>
            <FormControl
                accepter={CheckPicker}
                name="selSubmarkets"
                block={true} 
                data={this.props.submarkets}
                preventOverflow
            />
            <ControlLabel>Date Listed: </ControlLabel>
            <FormControl 
                accepter={DateRangePicker}
                name="dateListed"
                data={this.props.dateListed}
                block
                preventOverflow
            />
            <Button id="formSubmit" onClick={this.submitForm}>Submit</Button>
            <div id="formErrorMsg"></div>
            <Button id="formClear" onClick={this.clearForm}>Clear</Button>
            </Form>
        </div>
        )
    }

    handleChange = (value) => {
        this.setState({
            formValue: value
        });
    }

    submitForm = (e) => {
        this.props.onChange("submitForm", this.state, e)
    }

    clearForm = (e) => {
        this.props.onChange("clearForm", this.state, e)
    }
}

export default FilterForm;